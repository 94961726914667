import React, { useEffect, useState } from "react";
import Navbar from "../Component/Navbar";
import axios from "axios";
import Compbs from "../Component/Compbs";

export default function Guru() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [listguru, setListguru] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Dataguru");
      // console.log(response.data);
      setListguru(response.data);
    } catch (error) { }
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <Navbar title="Guru" />
      <div className="card" style={{ borderRadius: "0px" }}>
        {/* <img
          src="https://www.smknegeri1stabat.sch.id/imgs/1new.png"
          class="img-fluid"
          alt=""
        ></img> */}

        <div className="container mt-3">
          <div className="card border-0 shadow">
            <div className="card-body ">
              <h5 className="fw-bold"><i className="fas fa-user-circle"></i> Data guru SMK Negeri 1 Stabat</h5>
              <div className="text-secondary">Temukan data guru disini</div>
            </div>
          </div>
        </div>


        <div className="container mt-3">
          <div className="card">
            <div className="card-body">
              {listguru.map((data, index) => {
                return (
                  <div>
                    <div class="d-flex justify-content-between">
                      <div>
                        <img src="https://static-00.iconduck.com/assets.00/user-icon-1024x1024-dtzturco.png" className="img-fluid" style={{ height: '50px' }} />
                        {' '}<label>{data.nama}</label>
                      </div>
                      <div>
                        <small className="text-secondary text-success">{data.jabatan}</small>

                      </div>

                    </div>
                    <div><Compbs nip={data.nip} /></div>
                    <hr />
                  </div>
                )
              })}

            </div>
          </div>

        </div>



        {/* <div className="card-body">
          <label className="fw-bold">
            <small>
              <i className="far fa-user"></i> Data guru
            </small>
          </label>
          <div className="card mt-2">
            <div className="cad-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead style={{ fontSize: "10px" }}>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Nama</th>
                      <th scope="col">NIP</th>
                      <th scope="col">Pendidikan</th>
                      <th scope="col">B.Studi</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "10px" }}>
                    {listguru.map((data, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{data.nama}</td>
                          <td>{data.nip}</td>
                          <td>{data.pendidikan}</td>
                          <td>{data.bidang_studi}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
