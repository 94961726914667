import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function Loginguru() {
    const urlapi = process.env.REACT_APP_BASE_URL;
    const [nip, setNip] = useState('');
    const [pass, setPass] = useState('');
    const [show, setShow] = useState(false);
    const [pesan, setPesan] = useState(false)

    const navigate = useNavigate();

    const handleLogin = () => {
        getGuru();
    }

    const getGuru = async () => {
        try {
            const response = await axios.get(urlapi + "Dataguru?nip=" + nip + "&&pass=" + pass);
            console.log(response.data);
            if (response.data !== null) {
                localStorage.setItem('level', 'guru');
                localStorage.setItem('bidangstudi', response.data.bidang_studi);
                localStorage.setItem('nip', response.data.nip);
                localStorage.setItem('nama', response.data.nama);
                navigate('/mapel');
            } else {
                setPesan(true)
            }
        } catch (error) {
            setPesan(true)
        }
    }


    return (
        <div>
            <div className='card' style={{}}>
                <div className='card-body'>
                    <div className="image mt-2">
                        <center>
                            <img
                                src="img/img login.png"
                                class="img-fluid"
                                alt=""
                                style={{ height: "300px" }}
                            ></img>
                        </center>
                        <div className="fw-bold text-primary" style={{ fontSize: "30px" }}>
                            Login here
                        </div>
                        <label className="fw-bold">Welcome back you've been missed!</label>



                        {pesan == true ? <><div className='alert alert-danger mt-2 '>
                            <i className='fas fa-warning'></i> Nip anda salah atau tidak terdaftar
                        </div></> : ""}

                        <div className="card shadow mt-2 mb-5">
                            <div className="card-body">
                                <div className="form-group">
                                    <label className="text-primary fw-bold">NIP</label>
                                    <input
                                        type="text"
                                        name="username"
                                        className="form-control mt-1"
                                        placeholder="Input nip"
                                        style={{ borderRadius: "20px" }}
                                        value={nip}
                                        onChange={(e) => setNip(e.target.value)}
                                    ></input>
                                </div>

                                <div className="form-group mt-3">
                                    <label className="fw-bold text-primary">Password</label>
                                    <input
                                        type={show == false ? "password" : "text"}
                                        name="pass"
                                        value={pass}
                                        className="form-control mt-1"
                                        placeholder="Input password"
                                        style={{ borderRadius: "20px" }}
                                        onChange={(e) => setPass(e.target.value)}
                                    ></input>
                                </div>

                                <div className="form-check form-switch mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        onClick={() => setShow(!show)}
                                        checked={show}
                                    />
                                    <label
                                        class="form-check-label text-secondary"
                                        for="flexSwitchCheckChecked"
                                    >
                                        {show == false ? 'Show password' : 'Hide password'}

                                    </label>
                                </div>

                                <button
                                    className="btn btn-primary w-100 rounded-pill bg-primary mt-3 fw-bold"
                                    onClick={() => handleLogin()}
                                >
                                    <i className="far fa-circle-user"></i> Login
                                </button>

                                <div className="mt-3">
                                    {" "}
                                    {/* <small className="text-secondary">
                                        Belum punya akun ?{" "}
                                        <Link to="" style={{ textDecoration: "none" }}>
                                            daftar sekarang juga
                                        </Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
