import React, { useEffect, useState } from "react";
import Navbar from "../Component/Navbar";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Datasiswa from "../Component/Datasiswa";
import Compsiswa from "../Component/Compsiswa";

export default function Siswa() {
  const urlapi = process.env.REACT_APP_BASE_URL;

  const resvonsive = {
    0: {
      items: 3.5,
    },
    600: {
      items: 4.2,
    },
    1000: {
      items: 4.5,
    },
  };

  const [jurusan, setJurusan] = useState("");
  const [kelas, setKelas] = useState("X");
  const [listjurusan, setListjurusan] = useState([]);
  const [siswa, setSiswa] = useState([]);

  const getJurusan = async () => {
    const response = await axios.get(urlapi + "Jurusan");
    try {
      setListjurusan(response.data);
    } catch (error) { }
  };

  const getsiswa = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datasiswa?kelas=" + kelas + "&&jurusan=" + jurusan
      );
      //   console.log(response.data);
      setSiswa(response.data);
    } catch { }
  };

  const handleJurusan = (val) => {
    setJurusan(val);
  };

  const handlekelas = (val) => {
    setKelas(val);
  };

  useEffect(() => {
    getJurusan();
    getsiswa(kelas, siswa);
  }, [kelas, jurusan]);

  return (
    <div>
      <Navbar title="Siswa" />
      <div className="card border-0 shadow" style={{ borderRadius: "0px" }}>
        <div className="card-body">
          <div className="card shadow border-0">
            <div className="card-body">
              <h5 className="fw-bold"><i className="fas fa-user-circle"></i> Data Siswa SMK Negeri 1 Stabat</h5>
              <div className="text-secondary">
                Temukan data diri siswa anda disini
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-5">
          {/* <div className="d-flex justify-content-between">
            <small className="text-secondary">Pilih jurusan anda</small>
            <small className="text-secondary">
              <i className="fas fa-users"></i>
            </small>
          </div> */}
          {/* <hr /> */}
          <div className="row mt-2">
            {listjurusan.map((ls, index) => {
              return (
                <div key={index}>
                  <div className="col-sm-12 mt-2">
                    <div
                      className={
                        jurusan == ls.id ? "card border-primary" : "card"
                      }
                      onClick={() => handleJurusan(ls.id)}
                    >
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <small className="text-secondary">{ls.jurusan}</small>
                          <small className="text-secondary">
                            <i
                              className={
                                jurusan == ls.id
                                  ? "fas fa-angle-down"
                                  : "fas fa-angle-right"
                              }
                            ></i>
                          </small>
                        </div>
                      </div>
                      {jurusan == ls.id ? (
                        <>
                          <div className="card-body">
                            <Compsiswa jurusan={ls.jurusan} />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
