import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Kehadiranhariini() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [hariini, setHariini] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(
        urlapi + "Absensi?hariiniall=true&&nisn=" + localStorage.getItem("nisn")
      );
      setHariini(response.data);
      console.log(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className="card mt-2">
        <div className="card-body">
          {hariini == false ? (
            <>
              <img src="img/imgnull.png" class="img-fluid" alt="" />
              <center>
                <label className="text-center fw-bold">
                  Anda belum melakukan absensi hari ini !!
                </label>
              </center>
            </>
          ) : (
            <>
              <div>
                {hariini.map((data, index) => {
                  return (
                    <div key={index}>
                      <div
                        className={
                          data.hadir == 1
                            ? "card shadow bg-info text-white mt-3 border-0"
                            : "card shadow bg-warning text-white mt-3 border-0"
                        }
                      >
                        <div className="card-body">
                          {data.hadir == 1 ? (
                            <>
                              <small className="">
                                <i className="fas fa-user"></i> Hello{" "}
                                {data.nama}, Anda berhasil melakukan kehadiran
                              </small>
                            </>
                          ) : data.izin == 1 ? (
                            <>
                              <small className="">
                                <i className="fas fa-user"></i> Hello{" "}
                                {data.nama}, Anda berhasil melakukan izin
                              </small>
                            </>
                          ) : (
                            ""
                          )}
                          <hr />
                          <div
                            className="d-flex justify-content-between text-white"
                            style={{ fontSize: "12px" }}
                          >
                            <div className="fw-bold">
                              <i className="fas fa-book"></i>{" "}
                              <small>{data.bidang_studi}</small>
                            </div>

                            <div className="fw-bold">
                              <i className="fas fa-calendar-days"></i>{" "}
                              <small>{data.tgl}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
