import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function Compbs({ nip }) {
    const urlapi = process.env.REACT_APP_BASE_URL;
    const [studi, setStudi] = useState([]);

    const getdata = async () => {
        try {
            const response = await axios.get(urlapi + "Mapelguru?nip=" + nip);
            console.log(response.data);
            setStudi(response.data);
        } catch (error) {

        }
    }

    useEffect(() => {
        getdata();
    }, [])
    return (
        <div>
            {studi.map((data, index) => {
                return (
                    <div>
                        <small style={{ fontSize: '10px' }}> - {data.bidang_studi} </small>
                    </div>
                )
            })}

        </div>
    )
}
