import React from 'react'
import { Link } from 'react-router-dom'

export default function Intro() {
    return (
        <div>
            <div className='card border-0 shadow text-intro' >
                <div className='card-body'>
                    <br />
                    <br />


                    <h1 className='text-center fw-bold text-primary'>ABSENSIQU</h1>
                    <h5 className='text-center mb-4' style={{}}>Selamat datang di aplikasi<br /> AbsensiQU SMK Negeri 1 Stabat</h5>
                    <center>

                        <img src="https://presensi.ma-arrosyidiyah.sch.id/image/cam_scan.png"
                            class="img-fluid" alt="" style={{ height: '300px' }}></img>

                    </center>
                    <div className='mt-4 text-center'>
                        <small className='text-center'>Pilih role aplikasi yang ingin anda gunakan</small>
                    </div>

                    <div className='row mt-3 mb-5'>
                        <div className='col-sm-6 col-6'>
                            <div className='card bg-primary text-white'>
                                <Link to='/loginguru' style={{ color: 'white', textDecoration: 'none' }}>
                                    <div className='card-body'>
                                        <h2><i className='far fa-user'></i></h2>
                                        Login Guru <i className='fas fa-angle-right'></i>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-sm-6 col-6'>
                            <div className='card bg-primary text-white'>
                                <Link to='/login' style={{ color: 'white', textDecoration: 'none' }}>
                                    <div className='card-body'>
                                        <h2><i className='far fa-user'></i></h2>
                                        Login Siswa <i className='fas fa-angle-right'></i>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div >
    )
}
