import React from "react";
import QRCode from "react-qr-code";
export default function Kartu2() {
  return (
    <div>
      <div
        className="card card-depan shadow"
        style={{
          borderBottomRightRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
      >
        <div className="card-body">
          <center>
            <img
              src="img/logoscan2.png"
              className="img-fluid mt-1"
              alt=""
              style={{ height: "80px" }}
            ></img>

            <div></div>
          </center>
        </div>
      </div>
      <div
        className="card"
        style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
      >
        <div className="card-body ">
          <center>
            <QRCode
              value={localStorage.getItem("nisn")}
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            />

            {/* <h3 className="fw-bold color-black mt-2 text-warning">{nama}</h3>
            <p className="fw-bold text-primary" style={{ fontSize: '10px', lineHeight: '5px' }}>
              {jurusan}
            </p>
            <p className="fw-bold" style={{ lineHeight: "5px", fontSize: '10px' }}>
              KELAS : {kelas}
            </p> */}

            <div className="mt-3 fw-bold" style={{ fontSize: '12px' }}> Kode QR CODE hanya dapat di gunakan di sistem aplikasi absensi SMK Negeri 1 Stabat dan hanya dapat digunakan satu orang dalam melakukan absensi</div>

          </center>
        </div>
      </div >
    </div >
  );
}
