import React, { useEffect, useState } from "react";
import Navbar from "../Component/Navbar";
import Kartu from "../Component/Kartu";
import Kartu2 from "../Component/Kartu2";
import axios from "axios";

export default function Kartuqr() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [siswa, setSiswa] = useState([]);
  const url = "localhost/absensi/app/pdf?nisn=" + localStorage.getItem("nisn");

  const getSiswa = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datasiswa?nisn=" + localStorage.getItem("nisn")
      );
      setSiswa(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    getSiswa();
  }, []);
  return (
    <div>
      <Navbar title="Kartu QR" />
      <div className="card" style={{ borderRadius: "0px" }}>
        <div className="card-body">
          <div class="alert alert-primary" role="alert">
            <small className="fw-bold">
              Hello selamat datang di halaman kartu QR code
            </small>
          </div>

          <div className="card shadow">
            <div className="card-body">
              <div className="nama">
                <div className="row">
                  <div className="col-sm-6 col-12 mt-1">
                    <Kartu2 />
                  </div>
                  <div className="col-sm-6 col-12 mt-1">
                    <Kartu
                      nama={siswa.nama}
                      nisn={siswa.nisn}
                      jurusan={siswa.jurusan}
                      kelas={siswa.kelas}
                    />
                  </div>
                  <a
                    href={
                      "https://ratumerak.id/absensi/app/pdf?nisn=" +
                      siswa.nisn +
                      "&&nama=" +
                      siswa.nama
                    }
                    target="_blank"
                    className="btn btn-primary rounded-pill mt-3"
                  >
                    <i className="far fa-file"></i> Download kartu absensi
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
