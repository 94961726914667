import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function Register() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [nisn, setNisn] = useState("");
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [messnisn, setMessnisn] = useState("");
  const [akun, setAkun] = useState("");
  const [show, setShow] = useState(false);

  const handleRegister = async () => {
    await axios
      .post(urlapi + "Register", {
        nisn: nisn,
        username: username,
        password: pass,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data == true) {
          notify();
          setNisn("");
          setPass("");
          setPass2("");
          setUsername("");
          setAkun("");
          setMessnisn("");
        }
      })
      .catch((error) => {
        console.log(error.messsage);
      });
  };

  const cekNisn = async (e) => {
    try {
      const response = await axios.get(
        urlapi + "Register?ceknisn=true&&nisn=" + e
      );
      console.log(response.data);
      setMessnisn(response.data.message);
    } catch (error) { }
  };

  const cekAkun = async (e) => {
    try {
      const response = await axios.get(
        urlapi + "Register?cekakun=true&&nisn=" + e
      );
      console.log(response.data);
      setAkun(response.data.message);
    } catch (error) { }
  };

  const handleNisn = (e) => {
    cekNisn(e);
    cekAkun(e);
  };

  const notify = () => {
    toast.success("Register anda brhasil !", {
      position: "top-center",
    });
  };

  return (
    <div>
      <div
        className="card shadow"
        style={{
          borderRadius: "0px",
          height: "100%",
          width: "100%",
          border: "0px",
        }}
      >
        <div className="card-body">
          <div className="image mt-2">
            <center>
              <img
                src="img/img register.png"
                class="img-fluid"
                alt=""
                style={{ height: "300px" }}
              ></img>
            </center>
            <div className="fw-bold text-primary" style={{ fontSize: "30px" }}>
              Register here
            </div>
            <label className="fw-bold">Welcome back you've been missed!</label>
            <div className="card shadow mt-2 mb-5">
              <div className="card-body">
                <div className="form-group">
                  <label className="text-primary fw-bold">Nisn</label>
                  <input
                    type="text"
                    name=""
                    className="form-control mt-1"
                    placeholder="Input nisn"
                    style={{ borderRadius: "20px" }}
                    onKeyUp={(e) => handleNisn(e.target.value)}
                    onChange={(e) => setNisn(e.target.value)}
                    value={nisn}
                  ></input>
                  {messnisn == "true" && akun !== "true" ? (
                    <>
                      {" "}
                      <small className="text-success fw-bold">
                        Nisn anda sudah benar
                      </small>
                    </>
                  ) : messnisn == "false" ? (
                    <>
                      <small className="text-danger fw-bold">
                        Nisn anda belum benar
                      </small>
                    </>
                  ) : akun == "true" ? (
                    <>
                      <small className="text-danger fw-bold">
                        Nisn anda sudah digunakan
                      </small>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group mt-3">
                  <label className="text-primary fw-bold">Username</label>
                  <input
                    type="text"
                    name=""
                    className="form-control mt-1"
                    placeholder="Input username"
                    style={{ borderRadius: "20px" }}
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                  ></input>
                </div>

                <div className="form-group mt-3">
                  <label className="fw-bold text-primary">Password</label>
                  <input
                    type={show == false ? 'password' : 'text'}
                    name="username"
                    className="form-control mt-1"
                    placeholder="Input password"
                    style={{ borderRadius: "20px" }}
                    onChange={(e) => setPass(e.target.value)}
                    value={pass}
                  ></input>

                  {pass == '' ? <></> : pass.length < 6 ? <small className="text-warning">Password terlalu lemah</small> : <small className="text-success">Password sudah kuat</small>}

                </div>

                <div className="form-group mt-3">
                  <label className="fw-bold text-primary">
                    Ulangi password
                  </label>
                  <input
                    type={show == false ? 'password' : 'text'}
                    name="username"
                    className="form-control mt-1"
                    placeholder="Input password"
                    style={{ borderRadius: "20px" }}
                    onChange={(e) => setPass2(e.target.value)}
                    value={pass2}
                  ></input>
                  {pass2 != "" && pass != pass2 ? (
                    <>
                      <small className="text-danger fw-bold">
                        Password belum sama
                      </small>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="form-check form-switch mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    onClick={() => setShow(!show)}
                    checke={show == false ? false : true}
                  />
                  <label
                    class="form-check-label text-primary"
                    for="flexSwitchCheckChecked"
                  >
                    {show == false ? 'Show password' : 'Hide password'}

                  </label>
                </div>

                <button
                  onClick={() => handleRegister()}
                  className=" btn btn-primary w-100 rounded-pill bg-primary mt-3 fw-bold"
                  disabled={
                    username == "" ||
                      nisn == "" ||
                      pass == "" ||
                      pass2 == "" ||
                      pass != pass2 ||
                      messnisn == "false" ||
                      akun == "true"
                      ? true
                      : false
                  }
                >
                  <i className="far fa-circle-user"></i> Register
                </button>

                <div className="mt-3">
                  {" "}
                  <small className="text-secondary">
                    Sudah punya akun ?{" "}
                    <Link to="/login" style={{ textDecoration: "none" }}>
                      login sekarang juga
                    </Link>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
