import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Compsiswa({ jurusan }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [siswa, setSiswa] = useState([]);
  const [kelas, setKelas] = useState("");

  const getsiswa = async (kls) => {
    try {
      const response = await axios.get(
        urlapi + "Datasiswa?kelas=" + kls + "&&jurusan=" + jurusan
      );
      console.log(response.data);
      setSiswa(response.data);
    } catch {}
  };

  const handlekelas = (kls) => {
    setKelas(kls);
    getsiswa(kls);
  };

  useEffect(() => {
    getsiswa(kelas);
  }, []);
  return (
    <div>
      <small className="text-secondary">Pilih kelas siswa</small>
      <div className="row">
        <div className="col-sm-4 col-4">
          <div
            className={kelas == "X" ? "card border-success" : "card"}
            onClick={() => handlekelas("X")}
          >
            <div className="my-2 mx-2">
              <small className="text-secondary">Kelas X</small>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-4">
          <div
            className={kelas == "XI" ? "card border-success" : "card"}
            onClick={() => handlekelas("XI")}
          >
            <div className="my-2 mx-2">
              <small className="text-secondary">Kelas XI</small>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-4">
          <div
            className={kelas == "XII" ? "card border-success" : "card"}
            onClick={() => handlekelas("XII")}
          >
            <div className="my-2 mx-2">
              <small className="text-secondary">Kelas XII</small>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {siswa == "" ? (
        <>
          <center>
            {kelas == "" ? (
              <>
                <small>Tampilkan data berdasarkan kelas siswa</small>
              </>
            ) : (
              <>
                <small>Data siswa kelas {kelas} belume tersedia</small>
              </>
            )}
          </center>
        </>
      ) : (
        ""
      )}
      {siswa.map((data, index) => {
        return (
          <div key={index}>
            <img
              src="https://tse3.mm.bing.net/th?id=OIP.lkVN1WDlcV2jQCq-9LT7-wHaIJ&pid=Api&P=0&h=180"
              class="img-fluid rounded-fill"
              alt=""
              style={{ height: "30px" }}
            ></img>
            {data.nama}
            <hr />
          </div>
        );
      })}
    </div>
  );
}
