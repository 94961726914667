import React from "react";

export default function () {
  return (
    <div>
      <div
        className="card menu"
        style={{ height: "200px", borderRadius: "0px" }}
      >
        <div className="card-body">
          <h4 className="">
            <i className="fas fa-user-circle"></i> Hay{" "}
            {localStorage.getItem("username")}
          </h4>
        </div>
      </div>
    </div>
  );
}
