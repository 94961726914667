import React from "react";

export default function Navbar({ title }) {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-primary bg-primary">
        <div className="container">
          <a className="navbar-brand" href="#">
            <h3 style={{ fontWeight: 800, color: "white" }}>
              {" "}
              <i className="fas fa-qrcode"></i> {title}
            </h3>
          </a>
        </div>
      </nav>
    </div>
  );
}
