import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function () {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const [show, setShow] = useState(false);
  const [pesan, setPesan] = useState('');

  const navigate = useNavigate();

  const getLogin = async () => {
    try {
      const response = await axios.get(
        urlapi + "Login?username=" + username + "&&pass=" + pass
      );

      console.log(response.data);
      console.log(response.data.message);
      setPesan(response.data.message);
      if (response.data.message == "true") {
        localStorage.setItem("username", response.data.data.username);
        localStorage.setItem("nisn", response.data.data.nisn);
        localStorage.removeItem("level");
        navigate("/home");
      }
    } catch (error) { }
  };

  const handleLogin = () => {
    getLogin();
    setUsername("");
    setPass("");
    setShow(false);
  };

  return (
    <div>
      <div
        className="card shadow"
        style={{
          borderRadius: "0px",
          height: "100%",
          width: "100%",
          border: "0px",
        }}
      >
        <div className="card-body">
          <div className="image mt-2">
            <center>
              <img
                src="img/img login.png"
                class="img-fluid"
                alt=""
                style={{ height: "300px" }}
              ></img>
            </center>
            <div className="fw-bold text-primary" style={{ fontSize: "30px" }}>
              Login here
            </div>
            <label className="fw-bold">Welcome back you've been missed!</label>

            {pesan == 'gagal' ? <><div className="alert alert-danger mt-1">
              <i className="fas fa-warning"></i> Username dan password anda salah</div></> : pesan == 'false' ? <><div className="alert alert-warning"><i className="fas fa-warning"></i> Password  yang anda masukan salah</div></> : ''}

            <div className="a"></div>
            <div className="card shadow mt-2 mb-5">
              <div className="card-body">
                <div className="form-group">
                  <label className="text-primary fw-bold">Username</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control mt-1"
                    placeholder="Input username"
                    style={{ borderRadius: "20px" }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  ></input>
                </div>

                <div className="form-group mt-3">
                  <label className="fw-bold text-primary">Password</label>
                  <input
                    type={show == false ? "password" : "text"}
                    name="pass"
                    value={pass}
                    className="form-control mt-1"
                    placeholder="Input password"
                    style={{ borderRadius: "20px" }}
                    onChange={(e) => setPass(e.target.value)}
                  ></input>
                </div>

                <div className="form-check form-switch mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    onClick={() => setShow(!show)}
                    checked={show}
                  />
                  <label
                    class="form-check-label text-secondary"
                    for="flexSwitchCheckChecked"
                  >
                    {show == false ? 'Show password' : 'Hide password'}

                  </label>
                </div>

                <button
                  className="btn btn-primary w-100 rounded-pill bg-primary mt-3 fw-bold"
                  onClick={() => handleLogin()}
                >
                  <i className="far fa-circle-user"></i> Login
                </button>

                <div className="mt-3">
                  {" "}
                  <small className="text-secondary">
                    Belum punya akun ?{" "}
                    <Link to="/register" style={{ textDecoration: "none" }}>
                      daftar sekarang juga
                    </Link>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
