import React, { useEffect, useState } from "react";
import Navbar from "../Component/Navbar";
import axios from "axios";
import Kehadiranhariini from "../Component/Kehadiranhariini";

export default function Kehadiran() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [hariini, setHariini] = useState([]);
  const getHariini = async () => {
    try {
      const response = await axios.get(
        urlapi + "Absensi?hariini=true&&nisn=" + localStorage.getItem("nisn")
      );
      setHariini(response.data);
    } catch (error) { }
  };
  useEffect(() => {
    getHariini();
  }, []);

  return (
    <div>
      <Navbar title="Kehadiran" />
      <div className="card" style={{ borderRadius: "0px" }}>
        <div className="card-body">
          {/* <div className="card shadow border-0">
            <div className="card-body">
              <label className="fw-bold">Data kehadiran anda</label>
              <br />
              <small>Hello Aldi, tingakatkan kehadiran anda </small>
            </div>
          </div> */}

          <div className="card shadow border-0 mt-2">
            <div className="card-body">
              <label className="fw-bold text-success">
                <i className="fas fa-circle-user"></i> Hello{" "}
                {localStorage.getItem("nama")}
              </label>
              <br />

              {hariini != false ? (
                <>
                  <small className="text-success fw-bold">
                    Hari ini anda sudah melakukan absensi
                  </small>
                </>
              ) : (
                <>
                  <small className="text-danger fw-bold">
                    Hari ini anda belum melakukan absensi
                  </small>
                </>
              )}
            </div>
          </div>

          <div className="card shadow border-0 mt-2">
            <div className="card-body">
              <label className="fw-bold">
                <i className="fas fa-book"></i> Data Kehadiran anda
              </label>
              <br />
              <small className="text-success">
                Data kehadiran anda hari ini
              </small>
            </div>
          </div>

          {/* <div className="row mt-2" style={{ fontSize: "12px" }}>
            <div className="col-sm-6 col-6 ">
              <div className="card border-primary">
                <div className="card-body">
                  <small className="fw-bold">
                    Kehadiran hari ini <i className="fas fa-angle-right"></i>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-6">
              <div className="card">
                <div className="card-body">
                  <small className="fw-bold">
                    Data kehadiran anda <i className="fas fa-angle-right"></i>
                  </small>
                </div>
              </div>
            </div>
          </div> */}

          <Kehadiranhariini />
        </div>
      </div>
    </div>
  );
}
