// import logo from './logo.svg';
// import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import Home from "./pages/Home";
import About from "./pages/About";
import Siswa from "./pages/Siswa";
import Guru from "./pages/Guru";
import Kartuqr from "./pages/Kartuqr";
import Kehadiran from "./pages/Kehadiran";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Scanqr from "./pages/Scanqr";
import Loginguru from "./pages/Loginguru";
import Intro from "./pages/Intro";
import Mapel from "./pages/Mapel";
import Izin from "./pages/Izin";

function App() {
  return (
    <div className="App">
      <div className="row">
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
          <Router>
            <Routes>
              <Route path="/" element={<Intro />} />
              <Route path="/Login" element={<Login />} />
               <Route path="/Loginguru" element={<Loginguru />} />
              <Route path="/Register" element={<Register />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/siswa" element={<Siswa />} />
              <Route path="/guru" element={<Guru />} />
              <Route path="/kartuqr" element={<Kartuqr />} />
              <Route path="/kehadiran" element={<Kehadiran />} />
               <Route path="/mapel" element={<Mapel />} />
              <Route path="/scanqr/:kode_mapel" element={<Scanqr />} />
              <Route path="/izin" element={<Izin />} />
            </Routes>
          </Router>
        </div>
        <div className="col-sm-3"></div>
      </div>
    </div>
  );
}

export default App;
