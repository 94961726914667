import React, { useState } from "react";
import Navbar from "../Component/Navbar";
import { Scanner } from "@yudiel/react-qr-scanner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";

export default function Scanqr() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [value, setValue] = useState([]);
  const { kode_mapel } = useParams();

  const handle = (result) => {
    console.log(result[0].rawValue);
    setValue(result[0].rawValue);
    absen(result[0].rawValue);
  };
  const absen = async (nisn) => {
    await axios
      .post(urlapi + "Absensi", {
        nisn: nisn,
        nip: localStorage.getItem("nip"),
        kode_mapel: kode_mapel,
        bidang_studi: localStorage.getItem("bidangstudi"),
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status == "salah") {
          notify();
        } else if (response.data.status == "benar") {
          notify2();
        } else if (response.data.status == "doubel") {
          notify3();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const notify = () => {
    toast.error("Kode QR anda tidak terdaftar !", {
      position: "bottom-center",
    });
  };

  const notify2 = () => {
    toast.success("Anda berhasil absensi !", {
      position: "bottom-center",
    });
  };

  const notify3 = () => {
    toast.warning("Ops! anda sudah melakukan absensi", {
      position: "bottom-center",
    });
  };

  const keluar = () => {
    localStorage.removeItem("level");
    localStorage.removeItem("nip");
    window.location.replace("/loginguru");
  }
  return (
    <div>
      <Navbar title="Scanqr" />
      <div className="card shadow" style={{ borderRadius: "0px" }}>
        <div
          className="card-body"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          <div class="alert alert-primary border-0" role="alert">
            <div className="fw-bold">
              Scan kode QR siswa untuk melakukan pencatatan kehadiran siswa
            </div>
          </div>
          <div className="mt-2 ">
            <Scanner onScan={(result) => handle(result)} />
          </div>
          {/* 
          <div class="alert alert-primary border-0 shadow mb-5" role="alert">
            Pastikan kode QR anda terdaftar di aplikasi
          </div> */}
          <div className="bg-dark card mt-1">
            <h5 className="text-white text-center mt-4">
              Kode QR hanya dapat di scan oleh siswa SMK Negeri 1 Stabat.
            </h5>

            <div className="d-flex justify-content-around">
              <Link to='/mapel' className="text-center mb-3" style={{ textDecoration: 'none' }}>Mapel Lainya ? </Link>
              <Link to='#' onClick={() => keluar()} className="text-center mb-3" style={{ textDecoration: 'none' }}>Logout ? </Link>
            </div>

          </div>
          <center>

          </center>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
