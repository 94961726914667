// import { initializeApp } from "firebase/app";
// import { GoogleAuthProvider, getAuth } from "firebase/auth";
// import { getStorage } from "firebase/storage";
// import { getMessaging, getToken } from "firebase/messaging";
// import axios from "axios";

// const firebaseConfig = {
//   apiKey: "AIzaSyATXSkhQJ78yxNInpmmGp3mQBHMMexUfAc",
//   authDomain: "loginmedansoccers.firebaseapp.com",
//   projectId: "loginmedansoccers",
//   storageBucket: "loginmedansoccers.appspot.com",
//   messagingSenderId: "946648979024",
//   appId: "1:946648979024:web:259c9b1db48c087cb2e355",
//   measurementId: "G-RFZ72417RE",
// };

// const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app);
// export const provider = new GoogleAuthProvider();

// const appImage = initializeApp(firebaseConfig);
// export const imageDb = getStorage(appImage);

// // notification
// export const messaging = getMessaging(app);
// export const generateToken = () => {
//   Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {
//       console.log("Notification permission granted.");
//       getToken(messaging, {
//         vapidKey:
//           "BPFyeRSHWRx3Pa3OCKL61DETN9mfKUSo74QOukFCAt9qqZqaU1yFDuuDCJyZBmfFqGcvY-PPGwcCt85gkvJKr3k",
//       })
//         .then((currentToken) => {
//           if (currentToken) {
//             console.log(currentToken);
//             // const element = document.getElementById("token");
//             // element.innerHTML = currentToken;
//             if (currentToken !== "") {
//               savetoken(currentToken);
//             }
//           } else {
//             // Show permission request UI
//             console.log(
//               "No registration token available. Request permission to generate one."
//             );
//             // ...
//           }
//         })
//         .catch((err) => {
//           console.log("An error occurred while retrieving token. ", err);
//           // ...
//         });
//     }
//   });
// };

// export const savetoken = async (token) => {
//   const urlapi = process.env.REACT_APP_BASE_URL;
//   //   console.log("token user:", token);
//   await axios
//     .post(urlapi + "Add_token", {
//       id_user: localStorage.getItem("id"),
//       token: token,
//     })
//     .then((response) => {
//       console.log(response.data);
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  // apiKey: "AIzaSyATXSkhQJ78yxNInpmmGp3mQBHMMexUfAc",
  // authDomain: "loginmedansoccers.firebaseapp.com",
  // projectId: "loginmedansoccers",
  // storageBucket: "loginmedansoccers.appspot.com",
  // messagingSenderId: "946648979024",
  // appId: "1:946648979024:web:259c9b1db48c087cb2e355",
  // measurementId: "G-RFZ72417RE",

  // apiKey: "AIzaSyA6VxMqsAoiiTGTlhMO5k_ZxZ457VDwgd8",
  // authDomain: "minisoccerstorage.firebaseapp.com",
  // projectId: "minisoccerstorage",
  // storageBucket: "minisoccerstorage.appspot.com",
  // messagingSenderId: "134939382074",
  // appId: "1:134939382074:web:0f7bf88f064d4950988003",
  // measurementId: "G-HLWEF1VJ5B",

  // apiKey: "AIzaSyDNglEHk8z7IFTSAyW9YHirIeCgFOM5Vx4",
  // authDomain: "minisoccer-8c8cf.firebaseapp.com",
  // projectId: "minisoccer-8c8cf",
  // storageBucket: "minisoccer-8c8cf.appspot.com",
  // messagingSenderId: "1015896670803",
  // appId: "1:1015896670803:web:02a42d6367fd63214ebca6",
  // measurementId: "G-644K7C9V2H",

  // baru----

  // apiKey: "AIzaSyAzHbzAfLrX4S2Z_8VXAro_AWAzMmHcvwg",
  // authDomain: "mmsc-6c90f.firebaseapp.com",
  // projectId: "mmsc-6c90f",
  // storageBucket: "mmsc-6c90f.appspot.com",
  // messagingSenderId: "481888594645",
  // appId: "1:481888594645:web:32e53cbad594b362ef905c",
  // measurementId: "G-8NGZTEN7W0",

  // end----

  // baru lagi

  apiKey: "AIzaSyAkWpCVnc2knRi2tpcFZleQerGAg0NamYc",
  authDomain: "storage-fb24d.firebaseapp.com",
  projectId: "storage-fb24d",
  storageBucket: "storage-fb24d.appspot.com",
  messagingSenderId: "923908741147",
  appId: "1:923908741147:web:fa1b24fa4ef9371a6100be",
  measurementId: "G-L1P8NN3T81",

  // end----
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

const appImage = initializeApp(firebaseConfig);
export const imageDb = getStorage(appImage);
