import React, { useEffect, useState } from 'react'
import Navbar from '../Component/Navbar'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

export default function Mapel() {

    const navigate = useNavigate();

    const urlapi = process.env.REACT_APP_BASE_URL;
    const [mapel, setMapel] = useState([]);

    const getdata = async () => {
        try {
            const response = await axios.get(urlapi + "Mapelguru?nip=" + localStorage.getItem('nip'));
            console.log(response.data);
            setMapel(response.data)
        } catch (error) {

        }
    }
    useEffect(() => {
        if (localStorage.getItem('nip') == null) {
            navigate('/');
        }
        getdata();
    }, [])
    return (
        <div>
            <Navbar title="Mapel" />
            <div className='card border-0 shadow' style={{ borderRadius: '0', height: '700px' }}>
                <div className='card-body'>
                    <img src="https://www.smknegeri1stabat.sch.id/imgs/1new.png" class="img-fluid" alt="..."></img>
                    <div className='card border-0 shadow mt-2'>
                        <div className='card-body'>
                            <small className='fw-bold text-secondary'><i className='fas fa-circle-user'></i> {" "}Hello, Silahkan pilih mata pelajaran anda untuk memulai absensi</small>
                        </div>
                    </div>


                    <div className='row mt-3'>


                        {mapel.map((data, index) => {
                            return (
                                <div className='col-sm-6 col-6'>
                                    <Link to={"/scanqr/" + data.kode} style={{ textDecoration: 'none' }}>
                                        <div className='card mb-3 bg-primary text-white'>
                                            <div className='card-body'>
                                                <h4><i className='fas fa-book'></i></h4>
                                                <div className='fw-bold'>{data.bidang_studi}</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </div>
    )
}
