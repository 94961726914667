import React from "react";
import Navbar from "../Component/Navbar";
import Menu from "../Component/Menu";
import { Link } from "react-router-dom";

export default function Home() {
  const logout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("nisn");
    window.location.replace("/");
  };
  return (
    <div>
      <Navbar title="Absensiqu" />
      <Menu />
      <div className="card" style={{ borderRadius: "0px" }}>
        <div className="card-body">
          <div className="card card-menu border-0 shadow">
            <div className="card-body">
              <div className="row mt-2">
                <div className="col-sm-3 col-3 ">
                  <center>
                    <h3 className="text-primary">
                      <i className="fas fa-home"></i>
                    </h3>
                    <small
                      className="text-center text-primary"
                      style={{ fontSize: "10px" }}
                    >
                      Home
                    </small>
                  </center>
                </div>
                <div className="col-sm-3 col-3">
                  <center>
                    <Link to="/about" style={{ textDecoration: "none" }}>
                      <h3 className="text-primary">
                        <i className="fas fa-info-circle"></i>
                      </h3>

                      <small
                        className="text-center text-primary"
                        style={{ fontSize: "10px" }}
                      >
                        About
                      </small>
                    </Link>
                  </center>
                </div>
                <div className="col-sm-3 col-3">
                  <center>
                    <Link to="/siswa" style={{ textDecoration: "none" }}>
                      <h3 className="text-primary">
                        <i className="fas fa-users"></i>
                      </h3>
                      <small
                        className="text-center text-primary"
                        style={{ fontSize: "10px" }}
                      >
                        Siswa
                      </small>
                    </Link>
                  </center>
                </div>
                <div className="col-sm-3 col-3">
                  <center>
                    <Link to="/guru" style={{ textDecoration: "none" }}>
                      <h3 className="text-primary">
                        <i className="fas fa-user-circle"></i>
                      </h3>
                      <small
                        className="text-center text-primary"
                        style={{ fontSize: "10px" }}
                      >
                        Data Guru
                      </small>
                    </Link>
                  </center>
                </div>

                <div className="col-sm-3 col-3 mt-3">
                  <Link to="/izin" style={{ textDecoration: "none" }}>
                    <center>
                      <h3 className="text-primary">
                        <i className="fas fa-file"></i>
                      </h3>
                      <small
                        className="text-center text-primary"
                        style={{ fontSize: "10px" }}
                      >
                        Izin siswa
                      </small>
                    </center>
                  </Link>
                </div>

                <div className="col-sm-3 col-3 mt-3">
                  <center>
                    <Link to="/kehadiran" style={{ textDecoration: "none" }}>
                      <h3 className="text-primary">
                        <i className="fas fa-book"></i>
                      </h3>
                      <small
                        className="text-center text-primary"
                        style={{ fontSize: "10px" }}
                      >
                        Absensi
                      </small>
                    </Link>
                  </center>
                </div>

                <div className="col-sm-3 col-3 mt-3">
                  <center>
                    {localStorage.getItem("level") == "guru" ? (
                      <>
                        <Link to="/scanqr" style={{ textDecoration: "none" }}>
                          <h3 className="text-primary">
                            <i className="fas fa-qrcode"></i>
                          </h3>
                          <small
                            className="text-center text-primary"
                            style={{ fontSize: "10px" }}
                          >
                            Scan QR
                          </small>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to="/kartuqr" style={{ textDecoration: "none" }}>
                          <h3 className="text-primary">
                            <i className="fas fa-address-card"></i>
                          </h3>
                          <small
                            className="text-center text-primary"
                            style={{ fontSize: "10px" }}
                          >
                            Kartu QR
                          </small>
                        </Link>
                      </>
                    )}
                  </center>
                </div>

                <div
                  className="col-sm-3 col-3 mt-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => logout()}
                >
                  <center>
                    <h3 className="text-primary">
                      <i className="fas fa-user-plus"></i>
                    </h3>
                    <small
                      className="text-center text-primary"
                      style={{ fontSize: "10px" }}
                    >
                      Logout
                    </small>
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div
            class="alert alert-primary"
            role="alert"
            style={{ position: "relative", bottom: "130px" }}
          >
            <small className="fw-bold">
              {" "}
              Hello semua, selamat datang di aplikasi Absensi QR SMK Negeri 1
              Stabat
            </small>
          </div>

          <div
            class="alert alert-primary"
            role="alert"
            style={{ position: "relative", bottom: "135px" }}
          >
            <small className="fw-bold">
              {" "}
              Kehadiran anda menentukan prestasi anda
            </small>
          </div>

          <div
            className="card shadow mt-3"
            style={{ position: "relative", bottom: "135px" }}
          >
            <img
              src="https://www.smknegeri1stabat.sch.id/imgs/1new.png"
              class="img-fluid"
              alt="..."
            ></img>
          </div>

          <div
            className="card shadow mt-3"
            style={{ position: "relative", bottom: "135px" }}
          >
            <img
              src="https://www.smknegeri1stabat.sch.id/imgs/smkbisa.png"
              class="img-fluid"
              alt="..."
            ></img>
          </div>

          <div
            className="card shadow mt-3"
            style={{ position: "relative", bottom: "135px" }}
          >
            <img
              src="https://static.vecteezy.com/system/resources/previews/014/000/019/original/3d-banner-template-for-hand-scanning-qr-code-with-mobile-app-service-on-smartphone-design-vector.jpg"
              class="img-fluid"
              alt="..."
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
