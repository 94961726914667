import React from "react";
import Navbar from "../Component/Navbar";

export default function About() {
  return (
    <div>
      <Navbar title="About" />
      <div className="card" style={{ borderRadius: "0" }}>
        <div className="card-body">
          <img
            src="https://static.vecteezy.com/system/resources/previews/014/000/019/original/3d-banner-template-for-hand-scanning-qr-code-with-mobile-app-service-on-smartphone-design-vector.jpg"
            class="img-fluid"
            alt="..."
          ></img>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia
            pariatur quaerat ullam. Velit, esse. Sapiente est nam, cupiditate
            dolores quibusdam et labore itaque placeat assumenda nulla non
            recusandae, doloremque temporibus.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia
            pariatur quaerat ullam. Velit, esse. Sapiente est nam, cupiditate
            dolores quibusdam et labore itaque placeat assumenda nulla non
            recusandae, doloremque temporibus.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia
            pariatur quaerat ullam. Velit, esse. Sapiente est nam, cupiditate
            dolores quibusdam et labore itaque placeat assumenda nulla non
            recusandae, doloremque temporibus.
          </p>
        </div>
      </div>
    </div>
  );
}
