import React, { useEffect, useState } from "react";
import Navbar from "../Component/Navbar";
import axios from "axios";
import { Container, Form } from "react-bootstrap";
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";

export default function Izin() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [nisn, setNisn] = useState("");
  const [nama, setNama] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [kelas, setKelas] = useState("");
  const [izin, setIzin] = useState("");
  const [ket, setKet] = useState("");
  const [img, setImg] = useState("");
  const [valueimg, setValueimg] = useState("");
  const [nameimg, setNameimg] = useState("");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [dataizin, setDataizin] = useState(false);
  const [izindata, setIzindata] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datasiswa?nisn=" + localStorage.getItem("nisn")
      );
      console.log(response.data);
      setNisn(response.data.nisn);
      setNama(response.data.nama);
      setJurusan(response.data.jurusan);
      setKelas(response.data.kelas);
    } catch (error) {}
  };

  const handleChangeImg = (e) => {
    setImg(e.target.files[0]);
    console.log(e);
    setValueimg(e.target.value);
    setNameimg(e.target.files[0].name);
  };

  const handleIzin = () => {
    setLoading(true);
    const imgRef = ref(imageDb, `files/${nameimg}`);
    uploadBytes(imgRef, img);
    console.log(imgRef._location.path_);

    setTimeout(() => {
      getDownloadURL(ref(imageDb, `files/${nameimg}`)).then((url) => {
        console.log("prosess");

        setForm(false);
        addizin(url);
      });
    }, 15000);
  };

  const addizin = async (url) => {
    await axios
      .post(urlapi + "Addizin", {
        nisn: nisn,
        nama: nama,
        kelas: kelas,
        jurusan: jurusan,
        ket: ket,
        izin: izin,
        gambar: url,
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        notify();
      })
      .catch((error) => {});
  };

  const notify = () => {
    toast.success("Anda berhasil mengajukan izin !", {
      position: "bottom-center",
    });
  };

  const notify2 = () => {
    toast.error("Anda sudah melakukan izin hari ini !", {
      position: "bottom-center",
    });
  };

  const cekizin = async () => {
    try {
      const response = await axios.get(
        urlapi + "Addizin?nisn=" + localStorage.getItem("nisn")
      );
      console.log(response.data);
      setDataizin(true);
      setIzindata(response.data);
    } catch (error) {
      setDataizin(false);
    }
  };

  useEffect(() => {
    getdata();
    cekizin();
  }, []);

  return (
    <div>
      <Navbar title="Izin siswa" />
      <div className="card border-0 shadow" style={{ borderRadius: "0px" }}>
        <div className="card-body">
          <div className="card bg-primary text-white border-0 shadow">
            <div className="card-body">
              <h4>Hello {localStorage.getItem("username")}</h4>
              <div>
                Selamat datang di aplikasi absensi, anda dapat melakukan
                pengajuan izin kehadiran disini
              </div>
            </div>
          </div>
          {izindata.status == 0 ? (
            <>
              <div className="alert alert-warning fw-bold mt-3">
                <small>Status izin anda untuk hari ini belum disetuji</small>
              </div>
            </>
          ) : izindata.status == 1 ? (
            <>
              {" "}
              <div className="alert alert-success fw-bold mt-3">
                <small>Status izin anda untuk hari ini disetuji</small>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="alert alert-danger fw-bold mt-3">
                <small>Status izin anda untuk hari ditolak</small>
              </div>
            </>
          )}

          <div className="card mt-3">
            <div className="card-body">
              <div className="form-group">
                <label className="fw-bold">Nisn</label>
                <input
                  className="form-control"
                  type="text"
                  value={nisn}
                ></input>
              </div>
              <div className="form-group mt-3">
                <label className="fw-bold">Nama</label>
                <input
                  className="form-control"
                  type="text"
                  value={nama}
                ></input>
              </div>

              <div className="form-group mt-3">
                <label className="fw-bold">Jurusan</label>
                <input
                  className="form-control"
                  type="text"
                  value={jurusan}
                ></input>
              </div>

              <div className="form-group mt-3">
                <label className="fw-bold">Kelas</label>
                <select
                  className="form-control"
                  onChange={(e) => setKelas(e.target.value)}
                >
                  <option>{kelas}</option>
                  <option>-- Pilih Kelas --</option>
                  <option>X</option>
                  <option>XI</option>
                  <option>XII</option>
                </select>
              </div>

              <div className="form-group mt-3">
                <label className="fw-bold">Kategori Izin</label>
                <select
                  className="form-control"
                  onChange={(e) => setIzin(e.target.value)}
                >
                  <option value="">-- Pilih Kategori Izin --</option>
                  <option>Sakit</option>
                  <option>Pergi</option>
                  <option>Lainya</option>
                </select>
              </div>
              <br />

              <Form.Group
                className={izin == "Sakit" ? "mt-3 mb-3" : "d-none"}
                controlId="formBasicupload"
              >
                <label for="images" class="drop-container" id="dropcontainer">
                  <span class="drop-title">
                    {nameimg == "" ? (
                      "Upload surat keterangan sakit"
                    ) : (
                      <>
                        <i class="fa-solid fa-circle-check text-success"></i>{" "}
                        Upload sukses
                      </>
                    )}
                  </span>

                  <input
                    type="file"
                    id="images"
                    className="form-control"
                    accept="image/*"
                    onChange={(e) => handleChangeImg(e)}
                    required
                  />
                </label>
                <small>
                  Upload bukti keterangan ada sakit disini dengan format (JPG,
                  JPEG & PNG)
                </small>
              </Form.Group>

              <div className="form-group mt-3">
                <label className="fw-bold">Keterangan</label>
                <textarea
                  className="form-control"
                  value={ket}
                  onChange={(e) => setKet(e.target.value)}
                ></textarea>
              </div>

              {loading == true ? (
                <>
                  <div className="form-group mt-3">
                    <button className="btn btn-primary rounded-pill w-100 disabled">
                      Loading ....
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {dataizin == true ? (
                    <>
                      <div className="form-group mt-3">
                        <button
                          onClick={() => notify2()}
                          className="btn btn-primary rounded-pill w-100"
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-group mt-3">
                        <button
                          onClick={() => handleIzin()}
                          className="btn btn-primary rounded-pill w-100"
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
