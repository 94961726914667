import React from "react";

export default function Kartu({ nama, nisn, jurusan, kelas }) {
  return (
    <div>
      <div className="card card-depan shadow">
        <div className="card-body">

          <center>
            <img
              src="img/logoscan.png"
              className="img-fluid mt-2"
              alt=""
              style={{ height: "100px" }}
            ></img>
            <h3 className="fw-bold text-white mt-2">ABSENSI-QR</h3>
            <p className="fw-bold text-warning" style={{ lineHeight: "5px" }}>
              SMK NEGERI 1 STABAT
            </p>
            <div>
              <img
                src="img/tutwuri.png"
                className="img-fluid mt-4"
                alt=""
                style={{ height: "100px" }}
              ></img>
              <div className="fw-bold text-white mt-4">NISN:{nisn}<br /></div>
              <button className="btn btn-warning w-100 rounded-pill fw-bold ">{nama}</button>
              <br />
              <br />
              <div className="mt-3">
                <small className="text-white">www.smknegeri1stabat.ac.id</small>
              </div>
            </div>
          </center>
        </div>
      </div >
    </div >
  );
}
